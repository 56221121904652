{
  "name": "landing-app",
  "version": "4.2.2-develop.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me --port 4201",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "coverage": "browser-sync start --server ./coverage/landing-app --watch ''*.html"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.4",
    "@angular/cdk": "^17.2.2",
    "@angular/common": "^17.2.4",
    "@angular/compiler": "^17.2.4",
    "@angular/core": "^17.2.4",
    "@angular/forms": "^17.2.4",
    "@angular/material": "^17.2.2",
    "@angular/platform-browser": "^17.2.4",
    "@angular/platform-browser-dynamic": "^17.2.4",
    "@angular/router": "^17.2.4",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.3",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.2.3",
    "@angular/compiler-cli": "^17.2.4",
    "@angular/material-luxon-adapter": "^17.2.2",
    "@sparbanken-syd/personnummer": "^2.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.5",
    "@sparbanken-syd/user-documents-backend": "^0.0.1",
    "@types/jasmine": "~5.1.2",
    "@types/luxon": "^3.3.4",
    "@types/node": "^20.9.0",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "browser-sync": "^3.0.2",
    "eslint": "^8.53.0",
    "eslint-plugin-import": "^2.29.0",
    "eslint-plugin-jsdoc": "^46.9.0",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.3",
    "postcss": "^8.4.31",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.2.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.1.0",
    "typescript": "~5.2.2",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.0/xlsx-0.20.0.tgz"
  }
}
