import {Component} from '@angular/core'
import version from '../assets/package.json'
import {environment} from '../environments/environment'
import {ConfigService} from './services/config.service'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public version = version.version
  public settingsServiceUrl = environment.settingsServiceUrl

  constructor(public configService: ConfigService) {
  }
}
