import {inject} from '@angular/core'
import {Observable, of} from 'rxjs'
import {ConfigService} from '../services/config.service'

export const authGuard = (): Observable<boolean> => {
  const configService = inject(ConfigService)
  // In other cases it is a good idea to route to other places
  // instead of just say yes or no.
  return of(configService.isUserAdmin$() || configService.isLandingAppAdmin$())
}
