import {Injectable} from '@angular/core'

import {Observable} from 'rxjs'
import {UserService} from '../services/user.service'
import {Role} from '../services/types'

@Injectable({providedIn: 'root'})
export class RoleResolver  {
  constructor(private service: UserService) {
  }

  resolve(): Observable<Role[]> {
    return this.service.getAllRoles()
  }
}
